.ReactModal__Body--open {
  overflow: hidden;
  padding-right: 15px;
}

button,
a {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.has-error {
  input {
    border: 1px solid red !important;
  }
  span {
    color: red;
  }
}

.flex {
  display: flex;
  &.space-between {
    justify-content: space-between;
  }
  &.align-center {
    align-items: center;
  }
  &.center {
    justify-content: center;
  }
}

.text-align-center {
  text-align: center;
}

.form-register {
  input {
    border: 1px solid transparent !important;
  }

  th,
  td {
    border: none;
  }
}

.loader-container {
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  &.is-fullscreen {
    width: 100vw;
    height: 100vh;
  }
}

.loader {
  span {
    display: inline-block;
    width: 8px;
    height: 32px;
    background-color: gray;
    margin-right: 4px;
  }

  span:nth-child(1) {
    animation: grow 1s ease-in-out infinite;
  }

  span:nth-child(2) {
    animation: grow 1s ease-in-out 0.15s infinite;
  }

  span:nth-child(3) {
    animation: grow 1s ease-in-out 0.3s infinite;
  }

  span:nth-child(4) {
    animation: grow 1s ease-in-out 0.45s infinite;
  }
}
@keyframes grow {
  0%,
  100% {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    -o-transform: scaleY(1);
    transform: scaleY(1);
  }

  50% {
    -webkit-transform: scaleY(1.8);
    -ms-transform: scaleY(1.8);
    -o-transform: scaleY(1.8);
    transform: scaleY(1.8);
  }
}

.thumbnail {
  height: 156px;
}

.custom-checkbox {
  cursor: pointer;
  * {
    cursor: pointer;
  }
}

.invalid-input {
  input,
  select {
    border-color: #d92550;
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right calc(2.25rem / 4);
    background-size: calc(2.25rem / 2) calc(2.25rem / 2);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  }
  .custom-control-label {
    color: #d92550;
  }
}

.invalid-input-phone {
  input:first-of-type {
    border-top-color: #d92550;
    border-left-color: #d92550;
    border-bottom-color: #d92550;
  }
  input:last-of-type {
    border-top-color: #d92550;
    border-right-color: #d92550;
    border-bottom-color: #d92550;
    padding-right: 2.25rem;
    background-repeat: no-repeat;
    background-position: center right calc(2.25rem / 4);
    background-size: calc(2.25rem / 2) calc(2.25rem / 2);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  }
}

.card-header {
  justify-content: space-between;
  > .nav {
    width: unset;
  }
}

.btn-edit {
  i {
    font-size: 25px;
  }
}
.vertical-nav-menu {
  ul {
    overflow: hidden;
    max-height: 0;
    transition: max-height 200ms ease-in-out;
  }
  .mm-active {
    ul {
      max-height: 150px;
    }
  }
}

.phone-code {
  text-align: right;
  width: 62px !important;
  flex-grow: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.text-align-bottom {
  vertical-align: bottom !important;
}

.btn-fa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-numbers {
  text-align: right;
}

.invalid-feedback {
  display: block;
}
.svg-settings {
  /* Hiding this content since it's just settings. See - https://snook.ca/archives/html_and_css/hiding-content-for-accessibility */
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

/* linear gradient */
.fill-gradient-linear path {
  fill: url(#linear);
}

/* setting colors for linear gradient */
.linear-stop1 {
  stop-color: #ffa94d;
}

.linear-stop2 {
  stop-color: #e8590c;
}

/* radial gradient */
.fill-gradient-radial path {
  fill: url(#radial);
}

/* setting colors for radial gradient */
.radial-stop1 {
  stop-color: #69db7c;
}

.radial-stop2 {
  stop-color: #2f9e44;
}

.fill-solid {
  color: #4263eb;
}

.menu-item {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}
